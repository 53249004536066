import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import Nav from "../global/nav/Nav"
import HeaderContent from "../global/header/Content"
import HeaderImage from "../global/header/Image"
import CaseStudyHeaderIntro from "../global/header/CaseStudyIntro"
import HeaderGreenBoxOnTheRightSide from "../global/header/GreenBoxOnTheRightSide"

import {
  BannerContainer,
  LineWrapper,
  LineWrapperWithBottomSpace,
} from "../global/common/containers"

const CustomBannerContainer = styled(LineWrapper)`
  @media ${maxD.tabletL} {
    flex-direction: column;
  }
`

const CaseStudyHeader = ({ pageContext, data }) => {
  const header = data.acfCaseStudy.intro

  const isLogo = data.acfCaseStudy.csGrayLogo
  const isDescription = data.acfCaseStudy.csFirstDescription
  const isButton = data.acfCaseStudy.csLiveLink

  const image = {
    url: data.featuredImage?.node.localFile.childImageSharp.gatsbyImageData,
    alt: data.featuredImage?.node.altText,
  }

  const intro = {
    logo: {
      url: isLogo?.localFile.publicURL,
      alt: isLogo?.altText,
    },
    button: {
      title: isButton?.title,
      url: isButton?.url,
      target: isButton?.target,
    },
    description: isDescription,
  }

  return (
    <>
      <BannerContainer>
        <Nav pageContext={pageContext} />
        <CustomBannerContainer
          relative
          flex
          spaceBetween
          style={{ zIndex: "9" }}
        >
          <HeaderContent title={header} />
          {image.url && <HeaderImage url={image.url} alt={image.alt} />}
        </CustomBannerContainer>
      </BannerContainer>
      <LineWrapperWithBottomSpace relative flex spaceBetween>
        <HeaderGreenBoxOnTheRightSide
          containsSocialMedia
          style={{ bottom: "auto" }}
        />
        <CaseStudyHeaderIntro
          logo={intro.logo}
          description={intro.description}
          button={intro.button}
        />
      </LineWrapperWithBottomSpace>
    </>
  )
}

export default CaseStudyHeader
