import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { LineWrapper } from "../global/common/containers"
import {
  fadedGreenBox,
  fadedGreenBigBox,
  greenBox,
} from "../global/common/ornaments"
import { UnstyledList } from "../global/common/essentials"

const MockupsList = styled(UnstyledList)`
  display: flex;
  flex-wrap: wrap;
`

const MockupContainer = styled.li`
  --mockup-spacing: calc(var(--section-spacing) * 0.7);

  height: 600px;
  margin-bottom: var(--section-spacing);

  @media ${maxD.laptop} {
    height: 400px;
    margin-bottom: calc(var(--section-spacing) * 0.7);
  }

  @media ${maxD.tablet} {
    height: 70vw;
  }

  &:nth-child(odd) {
    &::after {
      top: -30px;
      left: 15px;
    }
  }

  &:nth-child(even) {
    &::after {
      bottom: -30px;
      right: 15px;

      @media ${maxD.tablet} {
        top: calc(
          var(--mockup-spacing) * -1 - ((65px - var(--mockup-spacing)) / 2)
        );
        z-index: 9;
      }
    }
  }

  &:nth-child(4n - 3),
  &:nth-child(4n - 2),
  &:nth-child(4n) {
    ${greenBox}
  }

  &:nth-child(4n - 3),
  &:nth-child(4n) {
    width: 100%;

    @media ${minD.tablet} {
      margin-left: var(--mockup-spacing);
    }
  }

  &:nth-child(4n - 2),
  &:nth-child(4n - 1) {
    width: calc(50% - (var(--mockup-spacing) / 2));

    @media ${minD.tablet} {
      ${fadedGreenBigBox}
    }

    @media ${maxD.tabletL} {
      width: calc(100% - var(--mockup-spacing));
    }

    @media ${maxD.tablet} {
      width: 100%;
    }
  }

  &:nth-child(4n - 2) {
    &::before {
      --box-height: 800px;

      top: calc(var(--mockup-spacing) * -2.5);
      left: 0;

      @media ${maxD.tabletL} {
        left: calc(var(--section-spacing) * -0.5);
      }
    }
  }

  &:nth-child(4n - 1) {
    ${fadedGreenBox}

    @media ${minD.tabletL} {
      margin-top: var(--mockup-spacing);
    }

    &::after {
      @media ${maxD.tabletL} {
        display: none;
      }
    }

    &::before {
      --box-height: 600px;

      top: calc(var(--mockup-spacing) * 2);
      left: var(--mockup-spacing);
    }
  }
`

const Mockup = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const Mockups = ({ mockups }) => (
  <LineWrapper>
    <MockupsList>
      {mockups &&
        mockups.map(mockup => {
          const url = mockup.localFile.childImageSharp.gatsbyImageData
          const alt = mockup.altText
          const id = mockup.id

          return (
            <MockupContainer key={id}>
              <Mockup
                image={url}
                alt={alt}
                style={{ position: "relative", overflow: "visible" }}
              />
            </MockupContainer>
          )
        })}
    </MockupsList>
  </LineWrapper>
)

export default Mockups
