import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { GalleryItem, ImageLink } from "./common"

const ClassicImage = ({ url, fullUrl, alt, onImageLinkClick }) => (
  <GalleryItem onClick={onImageLinkClick}>
    <ImageLink as="a" href={fullUrl}>
      <GatsbyImage image={url} alt={alt} className="full" />
    </ImageLink>
  </GalleryItem>
)

export default ClassicImage
