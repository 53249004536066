import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { maxD } from "../../assets/styles/helpers/devices"

import { LineWrapper } from "../global/common/containers"
import { UnstyledList } from "../global/common/essentials"
import { CenterTitle } from "../global/common/text"

const ScreenshotsList = styled(UnstyledList)`
  columns: 3;
  column-gap: 1.5rem;
  padding-bottom: 5rem;

  @media ${maxD.laptop} {
    padding-bottom: 4rem;
  }

  @media ${maxD.tabletL} {
    columns: 2;
    padding-bottom: 3rem;
  }

  @media ${maxD.mobileL} {
    columns: 1;
  }
`

const ScreenshotContainer = styled.li`
  padding-bottom: 1.5rem;

  page-break-inside: avoid;
  break-inside: avoid;
`

const Screenshots = ({ data }) => {
  const header = data.csScreenshotsTitle
  const screenshots = data.csScreenshots

  return (
    <LineWrapper>
      <CenterTitle
        dangerouslySetInnerHTML={{ __html: header }}
        style={{ maxWidth: "100%", "--padding-bottom": "5rem" }}
      />
      <ScreenshotsList>
        {screenshots &&
          screenshots.map(screenshot => {
            const id = screenshot.id
            const url = screenshot.localFile.childImageSharp.gatsbyImageData
            const alt = screenshot.altText

            return (
              <ScreenshotContainer key={id}>
                <GatsbyImage image={url} alt={alt} />
              </ScreenshotContainer>
            )
          })}
      </ScreenshotsList>
    </LineWrapper>
  )
}

export default Screenshots
