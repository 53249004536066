import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { GalleryItem, ImageLinkWithOverlay } from "./common"

const GreenImage = ({ url, fullUrl, alt, onImageLinkClick }) => (
  <GalleryItem onClick={onImageLinkClick}>
    <ImageLinkWithOverlay href={fullUrl} as="a">
      <GatsbyImage image={url} alt={alt} className="full" />
    </ImageLinkWithOverlay>
  </GalleryItem>
)

export default GreenImage
