import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { MainTitle } from "../global/common/text"
import { LineWrapper } from "../global/common/containers"
import { ClientLogo } from "../global/common/logos"
import { QuoteContainer } from "../global/common/ornaments"

import { Signature } from "../global/atoms/Signature"

const TestimonialContent = styled(LineWrapper)`
  padding-bottom: var(--section-spacing);
`

const TestimonialColumn = styled(QuoteContainer)`
  padding: calc(var(--section-spacing) * 0.6);
  z-index: 2;

  @media ${maxD.mobileM} {
    padding: 40px 20px;
  }

  &::after {
    top: 50%;
    right: calc(var(--quote-size) * -0.75);

    z-index: 9;

    @media ${maxD.laptop} {
      top: calc(var(--quote-size) * 0.75);
      right: calc(100% + var(--quote-size) * -0.5);
    }
  }
`

const TestimonialInner = styled.div`
  padding: var(--section-spacing) var(--section-spacing)
    calc(var(--section-spacing) * 0.7);

  display: flex;
  background-color: var(--dark-gray);
  color: #fff;

  @media ${maxD.laptop} {
    display: block;
  }

  @media ${maxD.tablet} {
    padding: var(--section-spacing) calc(var(--section-spacing) * 0.6) 40px;
  }
`

const SmallTestimonialTitle = styled(MainTitle)`
  padding-bottom: 130px;

  @media ${minD.tabletL} {
    padding-left: var(--section-spacing);
  }
`

const SmallImageContainer = styled(QuoteContainer)`
  flex-shrink: 0;
  width: 28vw;
  margin-top: calc(-70px - var(--section-spacing));
  padding-right: calc(var(--section-spacing) / 2);

  @media ${maxD.laptop} {
    width: 88vw;
    margin-bottom: 40px;
  }

  &::after {
    top: 80px;
    right: calc((var(--section-spacing) / 2) - 40px);
  }
`

const BigTestimonialTitle = styled(MainTitle)`
  --padding-bottom: calc(var(--section-spacing) * 0.8);

  @media ${maxD.laptop} {
    --padding-bottom: calc(var(--section-spacing) * 0.3);
  }
`

const BigImageContainer = styled.figure`
  @media ${minD.laptop} {
    flex-shrink: 0;
    width: 50%;
  }

  @media ${maxD.laptop} {
    height: calc(var(--section-spacing) * 6);
  }
`

const BigImage = styled(GatsbyImage)`
  z-index: -1;
`

const BigImageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: absolute;
  z-index: 9;

  width: 100%;
  height: 100%;

  padding: calc(var(--section-spacing) * 0.6);

  background: linear-gradient(
    180deg,
    transparent,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.7) 100%
  );

  @media ${maxD.mobileM} {
    padding: 30px 20px;
  }
`

const SmallImage = styled(GatsbyImage)`
  width: min(300px, calc(100vw - 110px));
  height: min(300px, calc(100vw - 110px));

  margin-bottom: 45px;
`

const Testimonial = ({ data }) => {
  const {
    longContent,
    personName,
    personPosition,
    whiteLogo,
    personImage,
    testimonialHeader,
    isBigTestimonial,
  } = data

  const image = {
    url: personImage?.localFile.childImageSharp.gatsbyImageData,
    alt: personImage?.altText,
  }

  const logo = {
    url: whiteLogo?.localFile.publicURL,
    alt: whiteLogo?.altText,
  }

  return (
    <>
      {isBigTestimonial ? (
        <>
          <TestimonialContent
            style={{
              paddingTop: "calc(var(--section-spacing) * 0.2)",
            }}
          >
            <TestimonialInner style={{ padding: 0 }}>
              <TestimonialColumn as="article">
                <BigTestimonialTitle
                  dangerouslySetInnerHTML={{ __html: testimonialHeader }}
                />
                <p dangerouslySetInnerHTML={{ __html: longContent }}></p>
              </TestimonialColumn>
              <BigImageContainer>
                {image.url && (
                  <BigImage
                    image={image.url}
                    style={{ backgroundPosition: "50% 20%" }}
                    className="full"
                  />
                )}
                <BigImageContent>
                  <Signature
                    name={personName}
                    position={personPosition}
                    style={{
                      "--padding-desktop": "0 0 1.5rem",
                      "--padding-mobile": "0 0 1rem",
                      zIndex: 99999,
                      isolation: "isolate",
                    }}
                    as="div"
                  />
                  {logo.url && <ClientLogo src={logo.url} alt={logo.alt} />}
                </BigImageContent>
              </BigImageContainer>
            </TestimonialInner>
          </TestimonialContent>
        </>
      ) : (
        <>
          <TestimonialContent>
            <SmallTestimonialTitle
              dangerouslySetInnerHTML={{ __html: testimonialHeader }}
            />
            <TestimonialInner>
              {image.url && (
                <SmallImageContainer>
                  <SmallImage
                    image={image.url}
                    alt={image.alt}
                    imgStyle={{ objectPosition: "top" }}
                  />
                  {logo.url && <ClientLogo src={logo.url} alt={logo.alt} />}
                </SmallImageContainer>
              )}
              <article>
                <Signature
                  name={personName}
                  position={personPosition}
                  style={{
                    "--padding-desktop": "0 0 1.5rem",
                    "--padding-mobile": "0 0 1rem",
                  }}
                />
                <p dangerouslySetInnerHTML={{ __html: longContent }}></p>
              </article>
            </TestimonialInner>
          </TestimonialContent>
        </>
      )}
    </>
  )
}

export default Testimonial
