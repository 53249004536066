import React from "react"
import styled from "styled-components"

import { LineWrapper } from "../global/common/containers"
import { CenterTitle } from "../global/common/text"
import BeforeAndAfterSlider from "./common/BeforeAndAfterSlider"

const BeforeAndAfterWrapper = styled(LineWrapper)`
  padding-bottom: var(--section-spacing);
`

const BeforeAndAfter = ({ data }) => {
  const header = data.csBeforeAfterTitle

  const before = {
    url: data.csBefore.localFile.childImageSharp.gatsbyImageData,
    alt: data.csBefore.altText,
  }

  const after = {
    url: data.csAfter.localFile.childImageSharp.gatsbyImageData,
    alt: data.csAfter.altText,
  }

  return (
    <BeforeAndAfterWrapper>
      <CenterTitle
        dangerouslySetInnerHTML={{ __html: header }}
        style={{ "--padding-bottom": "4.5rem", "--padding-top": "0" }}
      />
      <BeforeAndAfterSlider before={before} after={after} />
    </BeforeAndAfterWrapper>
  )
}

export default BeforeAndAfter
