import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { maxD } from "../../../assets/styles/helpers/devices"

const Mockup = styled.figure`
  width: 550px;
  flex-shrink: 0;
  min-height: 100%;

  padding: 100px 65px;

  @media ${maxD.laptopL} {
    width: 380px;
  }

  @media ${maxD.tabletL} {
    display: none;
  }
`

const MockupImage = styled(GatsbyImage)`
  top: 150px;
  margin: 0 auto;

  max-height: calc(100vh - 200px);
`

const MobileMockup = ({ url, alt }) => {
  return (
    <Mockup>
      <MockupImage
        image={url}
        alt={alt}
        objectFit="contain"
        style={{ position: "sticky" }}
      />
    </Mockup>
  )
}

export default MobileMockup
