import React from "react"
import styled from "styled-components"

import { SocialMedia } from "../SocialMedia"
import { GreenBox, GreenBoxTitle } from "./common"

import SiteLanguage from "../../../helpers/siteLanguage"

const GreenBoxOnTheRightSide = styled(GreenBox)`
  right: var(--wrapper-right-padding);
`

const HeaderGreenBoxOnTheRightSide = ({ containsSocialMedia, style }) => (
  <GreenBoxOnTheRightSide style={style}>
    {containsSocialMedia && (
      <>
        <GreenBoxTitle>
          {
            SiteLanguage() === "en" ?
              "You can also follow us on social media:"
            :
              "Śledź nas w mediach społecznościowych"
          }
        </GreenBoxTitle>
        <SocialMedia style={{ marginBottom: "0" }} />
      </>
    )}
  </GreenBoxOnTheRightSide>
)

export default HeaderGreenBoxOnTheRightSide
