import React from "react"
import styled from "styled-components"

import { maxD } from "../../../assets/styles/helpers/devices"

import { Button } from "../atoms/Button"
import { ClientLogo } from "../common/logos"
import { MiddleSpacedBigDescription } from "../common/text"

import { IntroSpacedOnMobile } from "./common"

const ClientLogoContainer = styled.header`
  @media ${maxD.tablet} {
    margin-top: 2.5rem;
  }
`

const CaseStudyHeaderIntro = ({ logo, description, button }) => {
  return (
    <IntroSpacedOnMobile>
      <ClientLogoContainer>
        {logo && <ClientLogo src={logo.url} alt={logo.alt} />}
      </ClientLogoContainer>
      {description && (
        <MiddleSpacedBigDescription
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {button && button.url && (
        <Button theme="green" href={button.url} target={button.target} as="a">
          {button.title}
        </Button>
      )}
    </IntroSpacedOnMobile>
  )
}

export default CaseStudyHeaderIntro
