import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { BigDescription } from "../global/common/text"

import SectionWithIndentedTitle from "../global/SectionWithIndentedTitle"

const SectionContent = styled.article`
  @media ${minD.tabletL} {
    width: 700px;
    flex-shrink: 0;
  }

  @media ${minD.laptop} {
    padding-left: calc(var(--container-left-margin-desktop, 120px) * 0.6);
  }

  @media ${minD.desktop} {
    width: 800px;
    padding-left: var(--container-left-margin-desktop, 120px);
  }

  @media ${maxD.tablet} {
    padding-top: var(--section-spacing);
  }
`

const AboutTheWork = ({ data }) => {
  const title = data.indentedSectionTitle

  const image = {
    url: data.indentedSectionImage?.localFile.childImageSharp.gatsbyImageData,
    alt: data.indentedSectionImage?.altText,
  }

  const subTitle = data.indentedSectionSubTitle
  const description = data.indentedSectionDescription

  return (
    <SectionWithIndentedTitle
      title={title}
      image={image}
      titleStyle={{ "--space": "70px" }}
      contentStyle={{ "--content-align": "center" }}
      wrapperStyle={{ paddingBottom: "calc(var(--section-spacing) - 5rem)" }}
    >
      <SectionContent>
        <BigDescription style={{ paddingBottom: "2.5rem" }}>
          {subTitle}
        </BigDescription>
        <p dangerouslySetInnerHTML={{ __html: description }}></p>
      </SectionContent>
    </SectionWithIndentedTitle>
  )
}

export default AboutTheWork
